<template>
    <div class="overlay" v-if="showPopup" @click="closePopup"></div>
    <div class="popup" style="width: 97%;background-color: transparent;border-color: transparent;" v-if="showPopup">
      <!-- Contenido del pop-up -->
      <button @click="closePopup" class="btn_cerrar_popUp" style="font-size:x-large;position: absolute;
       color:aliceblue;">X</button> 
      <div class="popup-content" >
        <div style="height: 100%">
            <div class="imagenPopUp__container" >
              <!-- <img src="@/assets/banners/es/fto_PopUp_PradoNorte.jpg" class="imagenPopUp" > -->
              <!-- <video  autoplay loop muted playsinline preload="auto" class="video " style=" object-fit: cover;">
                <source src="@/assets/bannerVideo/Farolito-Navidad-Final-HD.mp4" type="video/mp4" >
              </video> -->
            </div>
          </div>
        <!--<v-btn color="primary" block @click="dialog = false" >
            <img src="@/assets/banners/close.png" style="height:18px; position:fixed; z-index:2; left:96%;">
          </v-btn>-->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showPopup: true, // Cambia esto a false si no quieres que aparezca automáticamente
      };
    },
    methods: {
      closePopup() {
        this.showPopup = false;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos del pop-up */
  .overlay {
    position: fixed;
    top: 0;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Ajusta la opacidad según tus preferencias */
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 20;
  }

  @media screen and (min-width: 320px) {
    .video{
        width:100%; height:100%;
    }
  }
  @media screen and (min-width: 940px) {
    .video{
        width:80%; height:100%;    margin-bottom: -7vw;
    }
  }

  @media screen and (min-width: 1433px) {
    .video{
        width:75%; height:100%;    margin-bottom: -10vw;
    }
  }

  @media screen and (min-width: 2000px) {
    .video{
        width:75%; height:100%;    margin-bottom: -9vw;
    }
  }


  
  </style>
  